import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { BLOCKS } from "@contentful/rich-text-types";

import Layout from "../../components/layout";
import PageInfo from "../../components/pageInfo";
import PageTopper from "../../components/pageTopper";
import RenderRichText from "../../components/renderRichText";
import ImageFrame from "../../components/imageFrame";

const Index = ({ data: { contentfulPage } }) => {
  let options = {
    renderFuncs: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <ImageFrame
          key={node.data.target.title}
          image={getImage(node.data.target.gatsbyImageData)}
          alt={node.data.target.title}
          className="text-black"
          style={{ backgroundColor: contentfulPage.accentColor }}
          caption={node.data.target.description}
        />
      ),
    },
  };

  return (
    <Layout noContainer={!!contentfulPage.topperImage}>
      <PageInfo title={contentfulPage.title} />
      {contentfulPage.topperImage ? (
        <PageTopper
          image={getImage(contentfulPage.topperImage)}
          alt={contentfulPage.topperImage.title}
          className="mb-20"
        >
          <h1 className="text-6xl text-center mb-10 font-new-tegomin">
            {contentfulPage.title}
          </h1>

          <RenderRichText richText={contentfulPage.body} options={options} />
        </PageTopper>
      ) : (
        <>
          <h1 className="text-6xl text-center mb-10 font-new-tegomin">
            {contentfulPage.title}
          </h1>

          <RenderRichText richText={contentfulPage.body} options={options} />
        </>
      )}
    </Layout>
  );
};

export const query = graphql`
  query ($contentful_id: String!) {
    contentfulPage(contentful_id: { eq: $contentful_id }) {
      ...PageTemplateFields
    }
  }
`;

export default Index;
